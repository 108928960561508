import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import signInImage from "../../../assets/images/newcropped.png";
import octoposLogo from "../../../assets/images/cropped_logo.png";
import voriLogo from "../../../assets/images/Black_LOGO_with_Name copy.png";

import {
  AppContext,
  PosFormButton,
  PosInput,
  PosMainProgress,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";

const PublicRewardCardCreate = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [locationData, setLocationData] = useState([]);
  const [businessNameNotFound, setBusinessNameNotFound] = useState(1);
  const [locImgNotFound, setLocImgNotFound] = useState(1);
  const [locPhone, setLocPhone] = useState("");
  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const [date, setDate] = useState(null);
  const [businessImage, setBusinessImage] = useState("");
  const {
    isOpen: isRewardModalOpen,
    onOpen: onRewardModalOpen,
    onClose: onRewardModalClose,
  } = useDisclosure();

  const effectRun = useRef(true);
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      reward_card_number: "",
      terms_conditions: false,
      birth_day: "",
    },
    validationSchema: Yup.object({
      reward_card_number: Yup.number()
        .required(Constants.PHONE_IS_REQUIRED)
        .typeError(Constants.PHONE_NOT_VALID)
        .integer(Constants.PHONE_NOT_VALID)
        .min(100, Constants.PHONE_MIN_VALUE)
        .max(9999999999, Constants.PHONE_MAX_VALUE),
      email: Yup.string()
        .trim()
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
        .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
      first_name: Yup.string()
        // .trim()
        .nullable()
        .max(191, Constants.REWARD_CARD_FIRST_NAME_LENGTH_GREATE_THEN_255),
      terms_conditions: Yup.bool()
        .oneOf([true], "You must accept the terms and conditions")
        .required("Required"),
    }),
    onSubmit: (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      let payload = {
        ...values,
        birth_day:
          undefined != values.birth_day &&
          null != values.birth_day &&
          "" != values.birth_day
            ? moment(values.birth_day).format("MM/YYYY")
            : "",
        api_key: loaderResponse.data?.data?.api_key,
      };
      submit(payload, {
        method: Constants.POST,
        path: Constants.SIGNIN_PATH,
      });
    },
  });
  useEffect(() => {
    const subdomain = window.location.host.split(".");
    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      setBusinessImage(octoposLogo);
    } else {
      setBusinessImage(octoposLogo);
    }
  }, []);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocationData(loaderResponse.data?.data);

          if (
            null != loaderResponse.data?.data?.businessLogo &&
            "" != loaderResponse.data?.data?.businessLogo
          ) {
            setLocImgNotFound(0);
          }
          if (
            null != loaderResponse.data?.data?.businessName &&
            "" != loaderResponse.data?.data?.businessName
          ) {
            setBusinessNameNotFound(0);
          }
          let newVal = loaderResponse.data?.data?.location_data.phone
            .toString()
            .replace(/\D/g, "");
          if (
            loaderResponse.data?.data?.location_data.phone.toString().length ===
            0
          ) {
            newVal = "";
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            3
          ) {
            newVal = newVal.replace(/^(\d{0,3})/, "($1)");
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            6
          ) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            10
          ) {
            newVal = newVal.replace(
              /^(\d{0,3})(\d{0,3})(\d{0,4})/,
              "($1) $2-$3"
            );
          } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(
              /^(\d{0,3})(\d{0,3})(\d{0,4})/,
              "($1) $2-$3"
            );
          }
          setLocPhone(newVal);
          // formik.setFieldTouched("email", true);
          // formik.setFieldTouched("terms_conditions", true);
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [loaderResponse]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        // addToast({
        //   alertStatus: Constants.TOAST_TYPE_SUCESS,
        //   alertTitle: "Reward Customer Create",
        //   alertDescription: "Reward Customer successfully created.",
        // });
        onRewardModalOpen();
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.email.length <= 0 ||
      formik.errors.email ||
      formik.values.reward_card_number.length <= 0 ||
      formik.errors.reward_card_number ||
      formik.errors.terms_conditions ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };
  const handleCloseDialog = () => {
    onRewardModalClose();
    // window.location.reload(); // Reload the current page
    window.open(window.location.href, "_self").close(); // Attempt to close the current tab
  };

  return (
  <Flex
    bg={Colors.loginAuthBackground}
    minHeight={"100vh"}
    flexDirection={"column"}
  >
    <Box
      w={"100%"}
      sx={{
        position: "-webkit-sticky",
        /* Safari */ position: "sticky",
        top: "0",
        zIndex: 999,
      }}
    >
      <Box
        w={"100%"}
        sx={{
          /* Safari */ position: "absolute",
          top: "0",
          zIndex: 99999999,
        }}
      >
        {myContext.loading ? <PosMainProgress /> : null}
      </Box>
      <Flex
        // h={"100vh"}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        alignSelf={"center"}
      >
      <Box
        mt={{ base: 10, md: 20, sm: 18, lg: 28 }}
        ml={{ base: 5, md: 10, sm: 8, lg: 18 }}
        mr={{ base: 5, md: 10, sm: 8, lg: 18 }}
        // mb={{ base: 10, md: 20, sm: 16, lg: 28 }}
        bg={Colors.loginAuthBackground}
        style={{
          width: "54.13rem",
          borderRadius: "0.63rem",
          border: "0.13rem solid #FFF",
          boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
          paddingBottom: "2.81rem",
          boxSizing: "border-box",
        }}
      >
        <Box
          display="flex"
          mt={locationData.businessLogo != null &&
            "" != locationData.businessLogo ? "2rem" : "1rem"}
          justifyContent="center"
          alignItems="center"
        >
          {locationData.businessLogo != null &&
          "" != locationData.businessLogo ? (
            <Image
              style={{
                // width: "15.31rem",
                // marginTop: "4.69rem",
                height: "8.125rem",
              }}
              src={locationData.businessLogo}
              alt="Octopos"
            />
          ) : (null)
          // : (
          //   <Image
          //     style={{
          //       width: "15.31rem",
          //       // height: "8.125rem",
          //     }}
          //     src={businessImage}
          //     alt="Octopos"
          //   />
          // )
          }
        </Box>
        <Box
          color={Colors.posTextInfo}
          mt={"0.625rem"}
          style={{
            fontSize: "1.5rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {businessNameNotFound == 0 && 0 === locImgNotFound && (
            <Text
              fontSize={locationData.businessLogo != null &&
                "" != locationData.businessLogo ? "1.13rem":"1.73rem"}
                style={{
                // fontSize: "1.13rem",
                fontStyle: "1.9rem",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {locationData.business_name}
            </Text>
          )}
          {businessNameNotFound == 0 && 1 == locImgNotFound && (
            <Text
            fontSize={locationData.businessLogo != null &&
              "" != locationData.businessLogo ? "1.13rem":"1.73rem"}
              style={{
                // fontSize: "1.13rem",
                fontStyle: "1.9rem",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {locationData.business_name}
            </Text>
          )}
        </Box>
        <Box
          mt={"0.625rem"}
          mx={{ base: 4, md: 156 }}
          color={Colors.posTextInfo}
          style={{
            marginTop: "0.31rem",
            fontSize: "1.13rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          textAlign="center"
        >
          <Text>
          {null != loaderResponse.data?.data?.location_data.address1 &&
          "" != loaderResponse.data?.data?.location_data.address1
            ? loaderResponse.data?.data?.location_data.address1 + ", "
            : ""}
          </Text>
          <Text>
          {null != loaderResponse.data?.data?.location_data.city &&
          "" != loaderResponse.data?.data?.location_data.city
            ? loaderResponse.data?.data?.location_data.city + ", "
            : ""}
          {null != loaderResponse.data?.data?.location_data.state &&
          "" != loaderResponse.data?.data?.location_data.state
            ? loaderResponse.data?.data?.location_data.state + ", "
            : ""}
          {null != loaderResponse.data?.data?.location_data?.zipcode &&
          "" != loaderResponse.data?.data?.location_data?.zipcode
            ? locationData.location_data?.zipcode + ". "
            : ""}
          </Text>
          {/* {null != loaderResponse.data?.data?.location_data.country &&
          "" != loaderResponse.data?.data?.location_data.country
            ? loaderResponse.data?.data?.location_data.country
            : ""}
          . */}
        </Box>
        <Box
          color={Colors.posTextInfo}
          style={{
            marginTop: "0.31rem",
            fontSize: "1.13rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Ph- {null != locPhone && "" != locPhone ? locPhone : "N.A."}
        </Box>
        <Box mx={{ base: 4, md: 156 }} textAlign="left">
          <Stack spacing={2}>
            <Text
              mt={{ base: 5, md: 5, sm: 4, lg: 7 }}
              color={Colors.posTextInfo}
              style={{
                fontSize: "0.88rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
            >
              Join the Reward Program{" "}
              {loaderResponse.data?.data?.business_name != null &&
              loaderResponse.data?.data?.business_name != ""
                ? "at " + loaderResponse.data?.data?.business_name
                : ""}{" "}
            </Text>
            {/* <InputGroup
              bg={Colors.loginAuthInput}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                flexShrink: 0,
                borderRadius: "0.63rem",
              }}
            > */}
            {/* <InputLeftElement pointerEvents="none">
                <EmailIcon color={Colors.posNavbarLink} />
              </InputLeftElement> */}
            <PosInput
              id="reward_card_number"
              placeholder={"Phone Number*"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.reward_card_number}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "reward_card_number",
                  formik.values.reward_card_number.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInputGroup={true}
              inputType={"text"}
              inputError={
                formik.touched.reward_card_number &&
                formik.errors.reward_card_number
              }
              darkPlaceholder={true}
            >
              <PhoneIcon color="#5881FE" />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.reward_card_number &&
              formik.errors.reward_card_number ? (
                <span>{formik.errors.reward_card_number}</span>
              ) : null}
            </Text>
            {/* </InputGroup> */}
            <PosInput
              id="email"
              placeholder={"Email*"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.email}
              handleBlur={(e) => {
                formik.setFieldValue("email", formik.values.email.trim());
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInputGroup={true}
              inputType={"email"}
              inputError={formik.touched.email && formik.errors.email}
              darkPlaceholder={true}
            >
              <EmailIcon color="#5881FE" />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.email && formik.errors.email ? (
                <span>{formik.errors.email}</span>
              ) : null}
            </Text>
            <PosInput
              id="first_name"
              placeholder={"Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.first_name}
              posInput={true}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "first_name",
                  formik.values.first_name.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              inputType={"text"}
              inputError={formik.touched.first_name && formik.errors.first_name}
              darkPlaceholder={true}
            />
            {/* <PhoneIcon color="#5881FE" /> */}
            {/* <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} /> */}
            <Text color={Colors.errorColor}>
              {formik.touched.first_name && formik.errors.first_name ? (
                <span>{formik.errors.first_name}</span>
              ) : null}
            </Text>
            <Calendar
              placeholder="Birth Day (mm/yyyy)"
              value={formik.values.birth_day}
              onChange={(e) => formik.setFieldValue("birth_day", e.value)}
              view="month"
              dateFormat="mm/yy"
              maxDate={new Date()} // Sets the max date to today
            />
            <Box mt={"2rem"}>
              <Checkbox
                id="terms_conditions"
                name="terms_conditions"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isChecked={formik.values.terms_conditions}
              >
                By signing up for this reward program, I agree to following{" "}
                {/* <a
                  href="https://octopos.com/privacy-policy/"
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </a>{" "} */}
                <a
                  target="_blank"
                  href={window.location.origin + Constants.PUBLIC_REWARD_CARD_MOBILE_SMS_PRIVACY_POLICY_PATH}
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </a>
                {" "}and{" "}
                {/* <a
                  href="https://octopos.com/terms-conditions/"
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                  target="_blank"
                >
                  Terms & Conditions
                </a> */}
                <a
                  target="_blank"
                  href={window.location.origin + Constants.PUBLIC_REWARD_CARD_TERMS_SERVICES_PATH}
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                >
                  Terms of Services
                </a>
                .
              </Checkbox>
              <Text color={Colors.errorColor}>
                {formik.touched.terms_conditions && formik.errors.terms_conditions ? (
                  <span>{formik.errors.terms_conditions}</span>
                ) : null}
              </Text>
            </Box>
            <Button
              color={Colors.loginAuthBackground}
              bg={Colors.loginButtonBackground}
              onClick={formik.handleSubmit}
              // w={"100%"}
              isDisabled={
                formik.values.email.length <= 0 ||
                formik.errors.email ||
                formik.values.reward_card_number.length <= 0 ||
                formik.errors.reward_card_number ||
                formik.errors.terms_conditions ||
                buttonDisable
                  ? true
                  : false
              }
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "0.63rem",
                flexShrink: 0,
                gap: "0.63rem",
                borderRadius: "0.63rem",
                border: "1px solid" + Colors.loginButtonBoarder,
                boxShadow: "0px 1.63rem 2.5rem 0px" + Colors.loginButtonShadow,
                opacity: 1,
              }}
            >
              Submit
            </Button>
            <Text mt={{ base: "1rem", md: "1.25rem" }}>
              By signing up you agree to receive recurring automated marketing
              messages at the number provided. Reply STOP to opt-out or
              unsubscribe. Message & data rates apply.
            </Text>
          </Stack>
        </Box>
      </Box>
      <>
        <Modal
          isOpen={isRewardModalOpen}
          // onClose={onRewardModalClose}
          size={"lg"}
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Success!</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody>
              Welcome to {loaderResponse.data?.data?.business_name} Reward
              Program. Happy Savings!
            </ModalBody>
            <ModalFooter>
              <PosFormButton
                buttonText={"Close"}
                CancelButton={true}
                // ref={cancelRef}
                onClick={handleCloseDialog}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      </Flex>
    </Box>
  </Flex>)
};

export default RouteWithLayout(PublicRewardCardCreate);