import {
  Box,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLoaderData } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import "../../../assets/styles/App.css";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
  SingleOrderView,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { SimpleReportLayout } from "../../../layouts/index";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "./../../../helpers/utils/Utils";
import { getAllOrders, downloadOrderPDF } from "./OrderService";
import { identity } from "lodash";
const OrderList = (props) => {
  const myContext = useContext(AppContext);
  const loaderResponse = useLoaderData();
  const [orderData, setOrderData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const { error } = PosErrorHook();
  const [locations, setLocations] = useState([]);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const searchInputHideRef = useRef(false);
  const { addToast } = PosTostMessage();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [
    listOrderOlColumnVisibilityModel,
    setListOrderOlColumnVisibilityModel,
  ] = React.useState({
    // id: false,
  });
  const [orderId, setOrderId] = useState("");

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const [isVisible, setIsVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const columnNames = [
    { field: "id", headerName: "Order Id", sortable: true, width: 120 },
    {
      field: "local_id",
      headerName: "Local Id/Order No.",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location_name",
      headerName: "Location Name",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "ebt_flag",
      headerName: "EBT Tax Exemption",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "balance",
      headerName: "Total($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "paid",
      headerName: "Payment Status",
      sortable: true,
      ...actionColumn,
      renderCell: (params) => {
        let styles = {
          padding: "0.150rem 0.625rem",
          borderRadius: "0.63rem",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.94rem",
          fontStyle: "normal",
          fontWeight: 300,
          lineHeight: "normal",
          letterSpacing: "-0.01rem",
        };
        switch (params.value) {
          case "Paid":
            styles.backgroundColor = "#E4FEE2";
            styles.color = "#0B8C20B5";
            break;
          case "Partial Paid":
            styles.backgroundColor = "#5881FE1A";
            styles.color = "#010048"; 
            break;
          case "Unpaid":
            styles.backgroundColor = "#FCE6E5"; 
            styles.color = "#CD1919"; 
            break;
          default:
            styles.backgroundColor = "#5881FE1A";
            styles.color = "#010048"; 
            break;
        }
        return (
          <span style={styles}>
            {params.value}
          </span>
        );
      }
    },
    {
      field: "created_at",
      headerName: "Order Date",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "view",
      headerName: "View",
      filterable: false,
      sortable: false,
      resizable: false,
      width: 120,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.id);
          // handleViewPage(rowData.id);
        };
        return (
          <PosIconButton
            name={Constants.VIEW_BUTTON}
            onClick={handleView}
            viewIcon={true}
            width={"4.625rem"}
          />
        );
      },
    },
    {
      field: "pdf",
      headerName: "Download",
      filterable: false,
      sortable: false,
      resizable: false,
      width: 100,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          handlePdfReport(rowData.id);

          // myContext.handleLoading(true);
          // handleDisplayOrderData(rowData.id);
          // handleViewPage(rowData.id);
        };
        return (
          <PosIconButton
            // name={Constants.VIEW_BUTTON}
            onClick={handleView}
            pdfIcon={true}
            // width={"4.625rem"}
          />
        );
      },
    },
  ];
  const handlePdfReport = (id) => {
    myContext.handleLoading(true);
    try {
      downloadOrderPDF(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var url = Constants.REACT_APP_API_URL + response.data.data;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
      // }
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    // getRefundOrderData();/
  }, []);

  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      // fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      // toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
    }
  }, [
    paginationModel,
    // searchQuery,
    sortModel,
    formik.values.selectedLoactions,
    formik.values.fromDate,
    formik.values.toDate,
  ]);
  const handleSearchList = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    effectRun.current = true;
    // fetchData();
  };
  const fetchData = async () => {
    if (
      formik.values.selectedLoactions == "" &&
      locations.length > 1 &&
      ((formik.values.fromDate != null && formik.values.fromDate != "") ||
        (formik.values.toDate != "" && formik.values.toDate != null))
    ) {
      effectRun.current = false;
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.LOCATION_IS_REQUIRED,
      });
    } else {
      setLoading(true);
      let data = {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
        location: selectedLocation,

        // to_date: reportDateFormate(formik.values.toDate),
      };
      if (null !== formik.values.fromDate && "" !== formik.values.fromDate) {
        data.from_date = reportDateFormate(formik.values.fromDate);
      }
      if (null !== formik.values.toDate && "" !== formik.values.toDate) {
        data.to_date = reportDateFormate(formik.values.toDate);
      }
      try {
        getAllOrders(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setOrderData(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              setLoading(false);
              myContext.handleLoading(false);
            } else {
              setOrderData([]);
              setTotalCount(0);
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            if (
              !searchInputHideRef.current &&
              formik.values.fromDate == null &&
              formik.values.toDate == null &&
              formik.values.fromDate == "" &&
              formik.values.toDate == "" &&
              err?.response?.data?.data === 0
            ) {
              searchInputHideRef.current = true;
            }
            setOrderData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setOrderData([]);
        setTotalCount(0);
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  };
  const fetchDataAfterSearch = async (value) => {
    if (
      formik.values.selectedLoactions == "" &&
      locations.length > 1 &&
      ((formik.values.fromDate != null && formik.values.fromDate != "") ||
        (formik.values.toDate != "" && formik.values.toDate != null))
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.LOCATION_IS_REQUIRED,
      });
    } else {
      setLoading(true);
      let data = {
        limit: 25,
        page: 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: value,
        location: selectedLocation,
        // from_date: reportDateFormate(formik.values.fromDate),
        // to_date: reportDateFormate(formik.values.toDate),
      };
      if (null !== formik.values.fromDate && "" !== formik.values.fromDate) {
        data.from_date = reportDateFormate(formik.values.fromDate);
      }
      if (null !== formik.values.toDate && "" !== formik.values.toDate) {
        data.to_date = reportDateFormate(formik.values.toDate);
      }
      try {
        getAllOrders(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setOrderData(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              setLoading(false);
              myContext.handleLoading(false);
            } else {
              setOrderData([]);
              setTotalCount(0);
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            if (
              !searchInputHideRef.current &&
              formik.values.fromDate == null &&
              formik.values.toDate == null &&
              formik.values.fromDate == "" &&
              formik.values.toDate == "" &&
              err?.response?.data?.data === 0
            ) {
              searchInputHideRef.current = true;
            }
            setOrderData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setOrderData([]);
        setTotalCount(0);
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  };
  const fetchAfterClearSearchData = async () => {
    if (
      formik.values.selectedLoactions == "" &&
      locations.length > 1 &&
      ((formik.values.fromDate != null && formik.values.fromDate != "") ||
        (formik.values.toDate != "" && formik.values.toDate != null))
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.LOCATION_IS_REQUIRED,
      });
    } else {
      setLoading(true);
      let data = {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: "",
        location: selectedLocation,
        // from_date: reportDateFormate(formik.values.fromDate),
        // to_date: reportDateFormate(formik.values.toDate),
      };
      if (null !== formik.values.fromDate && "" !== formik.values.fromDate) {
        data.from_date = reportDateFormate(formik.values.fromDate);
      }
      if (null !== formik.values.toDate && "" !== formik.values.toDate) {
        data.to_date = reportDateFormate(formik.values.toDate);
      }
      try {
        getAllOrders(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setOrderData(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              setLoading(false);
              myContext.handleLoading(false);
            } else {
              setOrderData([]);
              setTotalCount(0);
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setOrderData([]);
            if (
              !searchInputHideRef.current &&
              formik.values.fromDate == null &&
              formik.values.toDate == null &&
              formik.values.fromDate == "" &&
              formik.values.toDate == "" &&
              err?.response?.data?.data === 0
            ) {
              searchInputHideRef.current = true;
            }
            setTotalCount(0);
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setOrderData([]);
        setTotalCount(0);
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  };
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );

  const modifiedData = orderData.map((order) => ({
    ...order,
    created_at: commonDateFormate(order.created_at),
  }));

  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderId("");
  };
  const handleClear = () => {
    setSearchQuery("");
    fetchAfterClearSearchData();
  };
  const handleOptionSelect = (e) => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "")){
          effectRun.current = true;
        }
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "")){
          effectRun.current = true;
        }
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "") ){
        effectRun.current = true;
      }
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const clearFilterDAta = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (searchQuery.length > 0) {
      setSearchQuery("");
      fetchAfterClearSearchData();
    }
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
    }
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
  };
  const renderReportLayout = () => {
    return (
      <>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 1, lg: 3, xl: 4 }}
          // minChildWidth='150px'
          // spacing='20px'
          // ml={4}
          mr={4}
          spacing={{
            base: "1rem",
            sm: "1rem",
            md: "1rem",
            lg: "1rem",
            xl: "1.2rem",
          }}
        >
          {locations.length > 1 && (
            <Box>
              <PosDropDown
                id="selectedLoactions"
                options={locations}
                value={selectedLocation}
                resetFilterOnHide={true}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                optionLabel="name"
                // showClear = {true}
                placeholder="Location"
                width={"100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
          )}
          {/* <Box
          mt="1rem"
            // className="card flex justify-content-center"
            // flexDirection={isExtraSmall ? "column" : "row"}
            // spacing={4}
            // mt={isMeduim ? 4 : 0}
          > */}
          <Box>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date"}
              width={"100%"}
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date"}
              width={"100%"}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                effectRun.current = setPaginationModel({
                  page: 0,
                  pageSize: 25,
                });
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
                if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "") ){
                  effectRun.current = true;
                }
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : undefined
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box>
            <PosFormButton
              isDisabled={
                searchQuery.length > 0 ||
                formik.values.selectedLoactions !== "" ||
                (formik.values.fromDate !== "" && formik.values.fromDate !== null) ||
                  (formik.values.toDate !== "" && formik.values.toDate !== null)
                  ? false
                  : true
              }
              onClick={() => {
                clearFilterDAta();
              }}
              buttonText={"Clear"}
              CancelButton={true}
            />
          </Box>
          {/* </Box> */}
        </SimpleGrid>
      </>
    );
  };
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    effectRun.current = true;
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
      formik.setFieldTouched("selectedLoactions", false);
    }
    formik.setFieldValue("fromDate", "");
    formik.setFieldTouched("fromDate", false);
    formik.setFieldValue("toDate", "");
    formik.setFieldTouched("toDate", false);
  };
  const apply = (value) => {
    // effectRun.current = true;

    // setLoading(true);
    // setApplyButtonClicked(1);
    // if (timeoutId) {
    //   clearTimeout(timeoutId);
    // }
    // const newTimeoutId = setTimeout(() => {
    fetchDataAfterSearch(value);
    // }, 250);
    // setTimeoutId(newTimeoutId);
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      // setPaginationModel({
      //   page: 0,
      //   pageSize: 25,
      // });
      effectRun.current = true;
      apply(value);
      // fetchAfterClearSearchData();
    }, 250);
    setTimeoutId(newTimeoutId);
  };
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderId}
        breadCrumNames={["Report", "Orders", "View Orders"]}
        handleClick={(i) => {
          if (i == 0) {
            props.navigate(Constants.REPORT_PATH);
          }
        }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Orders"]}
          breadCrumTitle={"Orders"}
        />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
        </ButtonGroup>
      </Flex>

      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        {" "}
        {!searchInputHideRef.current ? (
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            // gap={{ base: 2, md: 2 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            {/* <Box direction={"row"}> */}
            <PosSearch
              value={searchQuery}
              onChange={onChange}
              // onChange={(e) => {
              //   let value =
              //     searchQuery.length > 0 ? e.target.value : e.target.value.trim();
              //     setSearchQuery(value);
              //   // setPage(0);
              //   if (timeoutId) {
              //     clearTimeout(timeoutId);
              //   }

              //   const newTimeoutId = setTimeout(() => {
              //     effectRun.current = true;
              //     // fetchData();
              //   }, 250);

              //   setTimeoutId(newTimeoutId);
              // }}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Box
              display={"flex"}
              justifyContent={"center"}
              w={"3rem"}
              ml={{ base: 0, md: "1rem" }}
              mt={{ base: "1rem", md: 0 }}
            >
              <IconButton
                onClick={() => {
                  toggleVisibility();
                }}
                style={{
                  border: "1px solid #ccc",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                  variant: "posoutline",
                  color: Colors.posTextInfo,
                  borderColor: Colors.dividerColor,
                  borderRadius: "5px",
                  padding: 5,
                }}
              >
                <FilterListIcon style={{ color: Colors.posTextInfo }} />
              </IconButton>
            </Box>
          </Flex>
        ) : null}
        {isVisible && (
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
        )}
        {loading ? (
          <PosProgress />
        ) : totalcount > 0 ? (
          <Box padding={"1rem"}>
            <PosDataGridTable
              columns={columnNames}
              rows={modifiedData}
              totalcount={totalcount}
              columnVisibilityModel={listOrderOlColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setListOrderOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              sortingMode="server"
              onPaginationModelChange={setPaginationModel}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                sortRef.current = sortModel;
                setSortModel(newSortModel);
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              fileName={Constants.ORDER_CSV}
            />
          </Box>
        ) : (
          <Box padding={"1rem"}>
            <PosNoDataFound title={Constants.ORDER_NOT_FOUND} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(OrderList);
