import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  ChakraProvider,
  Divider,
  Flex,
  Image,
  Img,
  Input,
  Select,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useMediaQuery,
  VStack,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import { Checkbox } from "@mui/material";
import { Dialog } from "primereact/dialog";
import { Checkbox as PrimeReactCheckbox} from "primereact/checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import moment from "moment";
// import { AutoComplete } from "primereact/autocomplete";
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import invoice from "../../../assets/images/invoice.svg";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSwitchButton,
  PosTostMessage
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { endOfTodaysDay, startOfTodaysDay } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import {
  editManualProductList,
  editTransferOrderProductList,
  getProductForEdit,
} from "./TransferOrderService";
import { Search2Icon } from "@chakra-ui/icons";

const TransferOrderEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const locationRef = useRef(false);
  const fetchRef = useRef(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const checkedItemsRef = useRef(false)
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [productData, setProductData] = useState([]);
  const [allLocationProductFLag, setAllLocationProductFLag] = useState("");
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const oldselectedRef = useRef([])
  const [sortType, setSortType] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [viewSelectedProducts, setViewSelectedProducts] = React.useState([]);
  const [modalOpen, setModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedLocationData, setSelectedLocationData] = useState([]);
  const [locationAllProducts,setLocationAllProduct] = useState([])
  const [locationData, setLocationData] = useState([]);
  const [selectedVendorData, setSelectedVendorData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState("");
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [perchesOrderData, setPerchesOrderData] = useState([]);
  const [perchesOrderUserData, setPerchesOrderUserData] = useState([]);
  const [selectedQuantityUnit, setSelectedQuantityUnit] = useState(
    loaderResponse?.data?.data?.transfer_order?.status
  );
  const calendarRef = useRef(Calendar);
  const calendarRef1 = useRef(Calendar);
  const [isChecked, setIsChecked] = useState(false);
  const [addProductClicked, setAddProductClicked] = useState(false);
  const [thresholdProductClicked, setThresholdProductClicked] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    categories: false,
  });
  const selectedProductRef = useRef([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const selectedProdQtyRef = useRef([]);
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "md",
    xl: "lg",
  });
  const [products, setProducts] = useState([]);
  const [autoSelectedProducts, setAutoSelectedProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  const effectRunData = useRef(true);
  const inputRefs = useRef([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading1 = open && options.length === 0;
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const [timeoutId, setTimeoutId] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    isOpen: isProductModalOpen,
    onOpen: onProductModalOpen,
    onClose: onProductModalClose,
  } = useDisclosure();
  const selectedProductDataRef = useRef([]);
  const [paramData, setParamData] = useState(stateData.state);

  const sleep = (duration) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }
  const [value, setValue] = useState(options[0]);
  const [inputValueAuto, setInputValueAuto] = useState('');
  const [isAllProductsChecked, setIsAllProductsChecked] = useState(true);

  const [locationSourceData, setLocationSourceData] = useState([]);
  const [locationDestinationData, setLocationDestinationData] = useState([]);
  const [selectedLocationSourceData, setSelectedSourceLocationData] = useState([]);
  const [selectedLocationDestinationData, setSelectedDestinationLocationData] = useState([]);


// useEffect(() => {
//   let active = true;

//   if (!loading1) {
//     return undefined;
//   }

//   (async () => {
//     await sleep(450); // For demo purposes.
//     if (active) {
//       search();
//     }
//   })();

//   return () => {
//     active = false;
//   };
// }, [loading1]);
// useEffect(() => {
//   if (!open) {
//     setOptions([]);
//   }
// }, [open]);

// const search = () => {
//     let data = {
//       created_at: purchaseOrderData.created_at,
//       deleted_at: purchaseOrderData.deleted_at,
//       id: purchaseOrderData.id,
//       location: selectedLocationData.id,
//       location_id: selectedLocationData.id,
//       locationCity: purchaseOrderData.locations?.city,
//       locationCountry: purchaseOrderData.locations?.country,
//       locationState: purchaseOrderData.locations?.state,
//       vendors: {
//         id: selectedVendorData.id,
//       },
//       locations: {
//         id: selectedLocationData.id,
//       },
//       vendor: selectedVendorData.id,
//       users: {
//         id: perchesOrderUserData.id,
//       },
//       filter: inputValueAuto,
//       meilisearch: true,
//       query: {
//         limit: 1000,
//         page: 0,
//         order:
//           sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
//         order_type:
//           sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
//         filter: inputValueAuto,
//         allProdFlag: allLocationProductFLag === 1 ? 1 : 0,
//       },
//     };
//     try {
//       editManualProductList(data)
//         .then((response) => {
//           if (response.data.flag === true) {
//             setProducts(response.data.data.data);
//             setProductData(response.data.data.data);
//             setOptions(response.data.data.data);
//             setAllLocationProductFLag(
//               response.data.data.allLocationProductFLag
//             );
//             // setTimeout(() => {
//             //   let _filteredProducts;
//             //   if (!event.query.trim().length) {
//             //     _filteredProducts = [...response.data.data.data];
//             //   } else {
//             //     _filteredProducts = response.data.data.data.filter((product) => {
//             //       return (
//             //         (product.name.toLowerCase().includes(event.query.toLowerCase()) ||
//             //         product.barcode.toLowerCase().startsWith(event.query.toLowerCase()))
//             //       );
//             //     });
//             //   }
//             //   setFilteredProducts(_filteredProducts);
//             // }, 350);
//             setShowTable(false);
//             myContext.handleLoading(false);
//           } else {
//             setProducts([]);
//             setTotalCount(0);
//             setLoading(false);
//             setOptions([]);
//             setOpen(false);
//             setShowTable(false);
//             let actionData = response;
//             error({ actionData });
//             myContext.handleLoading(false);
//           }
//         })
//         .catch((err) => {
//           setProducts([]);
//           setTotalCount(0);
//           setOptions([]);
//           setOpen(false);
//           setLoading(false);
//           setShowTable(false);
//           let actionData = err;
//           error({ actionData });
//           myContext.handleLoading(false);
//         });
//     } catch (error) {
//       setProductData([]);
//       setTotalCount(0);
//       setLoading(false);
//       setOptions([]);
//       setOpen(false);
//       myContext.handleLoading(false);
//     }
// };

// const autoCompleteSelectedProducts= (pro) => {
//   if(undefined != pro && null != pro && '' != pro){
//     if(!checkedItems.includes(pro.id)){
//       var proAutoData = {
//         id: pro.id,
//         name: pro.name,
//         barcode: pro.barcode,
//         sku: pro.sku || "N.A.",
//         in_stock_qty: pro.in_stock_qty,
//         sale_price: pro.sale_price,
//         location: pro.location.name,
//         threshold: pro.threshold || "N.A.",
//         unit_case:pro.no_of_eaches || "",
//         autoCompleteProduct:true,
//         categories: pro.categories,
//       }
//       handleCheckboxChange({row:proAutoData});
//       window.scrollTo({
//         top: document.documentElement.scrollHeight,
//         behavior: "smooth",
//       });
//     }
//     else{
//       addToast({
//         alertStatus: Constants.TOAST_TYPE_WARNING,
//         alertTitle: Constants.TRANSFER_ORDER_MODULE,
//         alertDescription:'Product already exists.',
//       });
//       setAutoSelectedProducts("");
//       setInputValueAuto("");
//     }
//     // setAutoSelectedProducts('');
//   }
// }

  // const unselectProducts = () => {
  //   if (!isChecked) {
  //     // setIsChecked(false);
  //   } else {
  //     const selectedProductIds = viewSelectedProducts.map(
  //       (product) => product.product_id || product.id
  //     );
  //     setCheckedItems(selectedProductIds);
  //     setPerchesOrderData(selectedProdQtyRef.current);
  //   }
  //   // fetchData();
  // };
  // const handleSwitchChange = (e) => {
  //   setSelectedCategories([]);
  //   const checked = e.target.checked;
  //   setSearchQuery("");
  //   fetchRef.current = true;
  //   setIsChecked(checked);
  //   if (checked) {
  //   } else {
  //     unselectProducts();
  //   }
  // }; 
  // const handleAllProductSwitchChange = (e) => {
  //   setSelectedCategories([]);
  //   const checked = e.target.checked;
  //   setSearchQuery("");
  //   fetchRef.current = true;
  //   setIsAllProductsChecked(checked);
  //   const selectedProductIds = viewSelectedProducts.map(
  //     (product) => product.product_id || product.id
  //   );
  //   setCheckedItems(selectedProductIds);
  //   setPerchesOrderData(selectedProdQtyRef.current);
  //   if(isChecked){
  //     setIsChecked(false);
  //   }
  // }; 
  
  // const handleClear = () => {
  //   // clear input value and get all data
  //   fetchRef.current = true;
  //   setSearchQuery("");
  //   // effectRun.current = true;
  // };

  // const onSelectLocation = (e) => {
  //   if (undefined !== e.value) {
  //   locationRef.current = true;
  //   setSelectedLocationData(e.value);
  //   setPerchesOrderData([]);
  //   setViewSelectedProducts([]);
  //   setCheckedItems([]);
  //   setIsChecked(false);
  //   selectedProductRef.current = []
  //     setSelectAll(false);
  //   setIsLocationChanged(true);
  //   formik.setFieldValue("locations", e.value.name);
  //   setFilteredProducts('');
  //   setProducts([]);
  //   // setAutoSelectedProducts('');
  // }
  // };

  // const handleOptionSelect = (e) => {
  //   if (undefined !== e.value) {
  //   locationRef.current = true;
  //   setSelectedVendorData(e.value);
  //   setPerchesOrderData([]);
  //   setViewSelectedProducts([]);
  //   setCheckedItems([]);
  //   setIsChecked(false);
  //   selectedProductRef.current = []
  //     setSelectAll(false);
  //   setIsVendorChanged(true);
  //   formik.setFieldValue("vendors", e.value.name);
  //   setFilteredProducts('');
  //   setProducts([])
  //   // setAutoSelectedProducts('')
  // }
  // };

  // const handleCloseModal = () => {
  //   setModal(false);
  //   setSelectAll(false);
  //   // const selectedProductIds = viewSelectedProducts.map(
  //   //   (product) => product.product_id
  //   // );
  //   // setCheckedItems(selectedProductIds);
  //   const selectedProductIds = viewSelectedProducts.map(
  //     (product) => product.product_id || product.id
  //   );
  //   setCheckedItems(selectedProductIds);
  //   selectedProductRef.current = oldselectedRef.current
  //   setPerchesOrderData(selectedProdQtyRef.current);
  //   setSearchQuery("");
  //   setIsChecked(false);
  // };

  // function handleCheckboxChange(params) {
  //   const rowId = params.row.id;
  //   let sDataIndex = selectedProductData.findIndex((x) => x.id == rowId);
  //   if (sDataIndex > -1) {
  //     let array = [
  //       ...selectedProductData.slice(0, sDataIndex),
  //       ...selectedProductData.slice(sDataIndex + 1),
  //     ];
  //     setSelectedProductData(array);
  //   } else {
  //     let prodData = [...selectedProductData, params.row];
  //     setSelectedProductData(prodData);
  //   }
  //   let dataIndex = productData.findIndex((x) => x.id == rowId);
  //   let prodIndex = selectedProductRef.current.findIndex((x) => x.id == rowId);
  //   if (prodIndex <= -1 || undefined == params.row.autoCompleteProduct || !params.row.autoCompleteProduct) {
  //   if(prodIndex > -1) {
  //     selectedProductRef.current = [
  //       ...selectedProductRef.current.slice(0, prodIndex),
  //       ...selectedProductRef.current.slice(prodIndex + 1),
  //     ];
  //   } else {
  //     let getPayload = productData.filter((x) => x.id == rowId)
  //     selectedProductRef.current = [
  //       ...selectedProductRef.current,
  //       ...getPayload,
  //     ];
  //   }
  //   }
  //   let data = [];
  //   let array = [];
  //   if (checkedItems.includes(rowId)) {
  //     let index = checkedItems.findIndex((x) => x === rowId);
  //     if (index > -1) {
  //       data = [
  //         ...checkedItems.slice(0, index),
  //         ...checkedItems.slice(index + 1),
  //       ];
  //       array = [
  //         ...perchesOrderData.slice(0, index),
  //         ...perchesOrderData.slice(index + 1),
  //       ];
  //       setCheckedItems(data);
  //       setPerchesOrderData(array);
  //     }
  //   } else {
  //     let index = selectedProdQtyRef.current.findIndex((x) => x.id == rowId);
  //     // let prodId = locationAllProducts.findIndex((x) => x.id == rowId);
  //     let obj = {
  //       id: rowId,
  //       quantity: index > -1 ? parseInt(selectedProdQtyRef.current[index].quantity) : "",
  //       cost:
  //         index > -1
  //           ? selectedProdQtyRef.current[index]?.cost
  //           : null != productData[dataIndex]?.cost
  //           ? productData[dataIndex]?.cost
  //           : "",
  //       unit_case:
  //         index > -1
  //           ? selectedProdQtyRef.current[index]?.unit_case
  //           : null != productData[dataIndex]?.no_of_eaches
  //           ? productData[dataIndex]?.no_of_eaches
  //           : "",
  //       quantityUnit:
  //         index > -1 ? selectedProdQtyRef.current[index].quantityUnit : "CAS",
  //         costErrors:
  //         index > -1 ? selectedProdQtyRef.current[index].costErrors : "",
  //       quantityErrors:
  //         index > -1 ? selectedProdQtyRef.current[index].quantityErrors : "",
  //       unitCaseErrors:
  //         index > -1 ? selectedProdQtyRef.current[index].unitCaseErrors : "",
  //     };
  //     array = [...perchesOrderData, obj];
  //     data = [...checkedItems, rowId];
  //     setCheckedItems(data);
  //     setPerchesOrderData(array);
  //   }
  //   if(params.row.autoCompleteProduct){
  //     checkedItemsRef.current = true;
  //     effectRunData.current = true;
  //   }
  // }

  useEffect(() => {
    if(checkedItemsRef.current) {
      handleSelectedViewProduct();
      checkedItemsRef.current = false;
    }
  },[checkedItems]);

  const handleSelectedViewProduct = () => {
    setVisible(false);
    setModal(false);
    selectedProductRef.current = selectedProductData;
    let removeItems =  viewSelectedProducts.filter((product) =>
        checkedItems.includes(product?.product_id || product?.id)
      );
    let selectedProducts = [...removeItems];
    checkedItems.map((id) => {
      let index = selectedProducts.findIndex((x) => x?.id === id || x?.product_id === id);
      if (index <= -1) {
        let item = selectedProductRef.current.filter((product) => product?.id === id);
        // if(undefined !== item[0] && null !== item[0]){
        //   // selectedProducts.push(item[0]);
        //   let searchViewExist = selectedProducts.findIndex((x) => x.additionalRow == true);
        //   if(searchViewExist >= 0){
        //     selectedProducts.splice(selectedProducts.length-1, 0, item[0]);
        //   }
        //   else{
        //     selectedProducts.splice(selectedProducts.length, 0, item[0]);
        //   }
        // }
      }
    });
    setViewSelectedProducts(selectedProducts);
    setSearchQuery("");
    setIsChecked(false);
    onProductModalClose();
    effectRunData.current = true;
    focusInputByIndex();
  };
  

  // const handleSingleDelete = (id) => {
  //   if (checkedItems.includes(id)) {
  //     const updatedCheckedItems = checkedItems.filter(
  //       (itemId) => itemId !== id
  //     );
  //     setCheckedItems(updatedCheckedItems);
  //     const updatedSelectedProducts = viewSelectedProducts.filter(
  //       (product) => product.product_id !== id
  //     );
  //     setViewSelectedProducts(updatedSelectedProducts);
  //     const updatedPerchesOrderData = perchesOrderData.filter(
  //       (item) => item.id !== id
  //     );
  //     setPerchesOrderData(updatedPerchesOrderData);
  //     fetchData();
  //     setModal(false);
  //     setSearchQuery("");
  //   }
  // };

  const handleSingleDelete = (id) => {
    if (checkedItems.includes(id)) {
      let dataIndex = selectedProductData.findIndex((x) => x.id == id);
      if (dataIndex > -1) {
        let array = [
          ...selectedProductData.slice(0, dataIndex),
          ...selectedProductData.slice(dataIndex + 1),
        ];
        setSelectedProductData(array);
        selectedProductRef.current = array;
      }
      const updatedCheckedItems = checkedItems.filter(
        (itemId) => itemId !== id
      );
      setCheckedItems(updatedCheckedItems);
      const updatedSelectedProducts = viewSelectedProducts.filter(
        (product) => product.product_id !== id && product.id !== id
      );
      setViewSelectedProducts(updatedSelectedProducts);
      const updatedPerchesOrderData = perchesOrderData.filter(
        (item) => item.product_id !== id && item.id !== id
      );
      setPerchesOrderData(updatedPerchesOrderData);
      selectedProductRef.current = selectedProductRef.current.filter(
        (product) => product.product_id !== id && product.id !== id
      );
      // fetchData();
      // setModal(false);
      // setSearchQuery("");
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setPurchaseOrderData(loaderResponse.data.data.transfer_order);
          setLocationSourceData(loaderResponse.data?.data?.locations);
          setLocationDestinationData(loaderResponse.data?.data?.locations);
          setSelectedSourceLocationData(loaderResponse.data?.data?.transfer_order.source_location);
          setSelectedDestinationLocationData(loaderResponse.data?.data?.transfer_order.destination_location);
          // const vendors = loaderResponse.data.data.vendor;
          // const purchaseOrderVendorId =
          //   loaderResponse.data.data.transfer_order.vendors.id;
          // const matchingVendors = vendors.find(
          //   (vendor) => vendor.id === purchaseOrderVendorId
          // );
          // setSelectedVendorData(matchingVendors);
          // // setLocationData(loaderResponse.data.data.location);
          // const locations = loaderResponse.data.data.location;
          // const purchaseOrderLocationId =
          //   loaderResponse.data.data.transfer_order.locations.id;
          // const matchingLocation = locations.find(
          //   (location) => location.id === purchaseOrderLocationId
          // );
          // setSelectedLocationData(matchingLocation);
          setViewSelectedProducts(
            loaderResponse.data.data.transfer_order.transfer_order_items
          );
          let array = [];
          loaderResponse.data.data.transfer_order.transfer_order_items.map(
            (item_obj) => {
              let obj = {
                id: item_obj.product_id,
                received_qty: undefined != item_obj.received_qty && null != item_obj.received_qty && '' != item_obj.received_qty ? parseInt(item_obj.received_qty) : parseInt(item_obj.quantity),
                unit_of_measures:item_obj.product_unit_measures,
                measureUnit:item_obj.selected_unit_of_measure,
                unit_quantity:''
              };
              const umData = item_obj.product_unit_measures.find(
                (um) => um.id === item_obj.selected_unit_of_measure
              );
              if(undefined != umData && null != umData && '' != umData){                
                obj.unit_quantity = parseInt(obj.quantity) * parseFloat(umData.base_qty).toFixed(2);
              }
              // array.push(obj);
              array.splice(array.length, 0, obj);
            }
          );
          if (array.length > 0) {
            setPerchesOrderData(array);
          }
          const productDetails =
            loaderResponse.data.data.transfer_order.transfer_order_items;
          const productIds = productDetails.map(
            (product) => product.product_id
          );
          setCheckedItems(productIds);
          setPerchesOrderUserData(loaderResponse.data.data.transfer_order.users);
          if (buttonClicked) {
            fetchData();
            setModal(true);
            setButtonClicked(false);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.TRANSFER_ORDER_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TRANSFER_ORDER_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse, buttonClicked]);
  const modifiedData = productData.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    sku: data.sku || "N.A.",
    in_stock_qty: data.in_stock_qty,
    sale_price: data.sale_price,
    location: data.location.name,
    threshold: data.threshold || "N.A.",
    unit_case:data.no_of_eaches || "",
    category_name: data.categories.map((cat) => cat.name).join(", "),
  }));
  const [selectAll, setSelectAll] = useState(false);
  
  const focusInputByIndex = () => {
  if (inputRefs.current[modifiedViewDatas.length-1]) {
    inputRefs.current[modifiedViewDatas.length-1].focus();
  }
};
  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     let ids = checkedItems.map((row) => {
  //       if(modifiedData.filter((x) => x.id == row.id).length === 0){
  //         return row
  //       }
  //     })
  //     let allProdIds =  modifiedData.map((row) => row.id)
  //     let checkedIds = [...ids,...allProdIds]
  //     setCheckedItems(checkedIds);
  //     let prod = productData.map((row) => {
  //       let index = selectedProductRef.current.findIndex(x => x.id === row.id)
  //       if(index <= -1){
  //         return row
  //       }
  //     })
  //     selectedProductRef.current=[...selectedProductRef.current,...prod];
  //     let array = []
  //     checkedIds.map((rowId) => {
  //       let dataIndex = locationAllProducts.findIndex((x) => x.id == rowId);
  //       let index = selectedProdQtyRef.current.findIndex((x) => x.id == rowId);
  //       let obj = {
  //         id: rowId,
  //         quantity: index > -1 ? selectedProdQtyRef.current[index].quantity : "",
  //         cost:
  //           index > -1
  //             ? selectedProdQtyRef.current[index].cost
  //             : undefined != locationAllProducts[dataIndex]?.cost &&
  //               null != locationAllProducts[dataIndex]?.cost
  //             ? locationAllProducts[dataIndex]?.cost
  //             : "",
  //         quantityUnit:
  //           index > -1 ? selectedProdQtyRef.current[index].quantityUnit : "CAS",
  //         costErrors:
  //           index > -1 ? selectedProdQtyRef.current[index].costErrors : "",
  //         quantityErrors:
  //           index > -1 ? selectedProdQtyRef.current[index].quantityErrors : "",
  //       };
  //       array.push(obj)
  //     })
  //     setPerchesOrderData(array);
  //     setSelectAll(true);
  //   } else {
  //     selectedProductRef.current = [];
  //     setCheckedItems([]);
  //     setPerchesOrderData([]);
  //     setSelectAll(false);
  //   }
  // };
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  // const selectAllProduct = (isChecked) => {
  //   const pageData = modifiedData;
  //   const selectedIds = pageData.map((data) => data.id);
  //   let updatedCheckedItems, updatedSelectedProductData, updatedPerchaseData;
  //   if (isChecked) {
  //     // If the checkbox is checked, select all items on the current page      
  //     updatedCheckedItems = [...checkedItems, ...selectedIds.filter((id) => !checkedItems.includes(id))];
  //     updatedSelectedProductData = [
  //       ...selectedProductData,
  //       ...pageData.filter((data) => !selectedProductData.some((item) => item.id === data.id))
  //     ];
  //     // const newPageData = pageData
  //     //   .filter(pageItem => !perchesOrderData.some(prodItem => prodItem.id === pageItem.id))
  //     //   .map(data => ({
  //     //     id: data.id,
  //     //     cost_per_unit: "", 
  //     //     quantity: "",
  //     //     quantityUnit:"CAS",
  //     //     total_unit:"",
  //     //     unit_case:"",
  //     //     cost:""
  //     //   }));
  //     updatedPerchaseData = [...perchesOrderData];
  //   } else {
  //     // If the checkbox is unchecked, unselect all items on the current page      
  //     updatedCheckedItems = checkedItems.filter((id) => !selectedIds.includes(id));
  //     updatedSelectedProductData = selectedProductData.filter(
  //       (item) => !selectedIds.includes(item.id)
  //     );
  //     updatedPerchaseData = perchesOrderData.filter(
  //       (item) => !selectedIds.includes(item.id)
  //     );
  //   }
  //   setCheckedItems(updatedCheckedItems);
  //   setSelectedProductData(updatedSelectedProductData);
  //   setPerchesOrderData(updatedPerchaseData);
  // };
  // const onCheckboxSelectionChange = (e) => {
  //   const isChecked = e.target.checked; // This will be true if the checkbox is being checked, and false if it's being unchecked.
  //   setAllChecked(isChecked);
  //   selectAllProduct(isChecked);
  // };
  // const openDialog = (index) => {
  //   var CheckedProducts = [];
  //   if (viewSelectedProducts.length > 0) {
  //     viewSelectedProducts.map((dt) => {
  //       if(undefined != dt.product_id && null != dt.product_id && '' != dt.product_id){
  //         CheckedProducts.push(dt.product_id);
  //       }
  //       else{
  //         CheckedProducts.push(dt.id);
  //       }
  //     });
  //   }
  //   setCheckedItems(CheckedProducts);
  //   selectedProdQtyRef.current = perchesOrderData;
  //   const updateData=perchesOrderData.map((item) => ({
  //     ...item
  //   }));
  //   setPerchesOrderData(updateData)
  //   oldselectedRef.current = selectedProductRef.current;
  //   setIsDialogOpen(true);
  //   setPaginationModel({
  //     page: 0,
  //     pageSize: 100,
  //   });
  //   fetchRef.current = true;
  //   setSortModel([
  //     {
  //       field: "name",
  //       sort: "asc",
  //     },
  //   ]);
  //   setSelectedCategories([]);
  //   setIsAllProductsChecked(true);
  //   onProductModalOpen();
  // };
  // const modalClose = () => {
  //   setVisible(false);
  //   setSelectedProductData(selectedProductRef.current);
  //   const selectedProductIds = viewSelectedProducts.map(
  //     (product) => product.id
  //   );
  //   setCheckedItems(selectedProductIds);
  //   selectedProductRef.current = oldselectedRef.current;
  //   setPerchesOrderData(selectedProdQtyRef.current);

  //   setSearchQuery("");
  //   onProductModalClose();
  //   setSelectedCategories([]);
  //   setIsChecked(false);
  // };
  // const onHideDialog = () => {
  //   formik.resetForm();
  //   setSelectedCategories([]);
  //   setVisible(false);
  // };
  // const clickIfapplyCat = (cat) => {
  //   setSelectedCategories(cat);
  //   paginationModel.page = 0;
  //   apply();
  // };
  // const apply = () => {
  //   fetchRef.current = true;
  //   setLoading(true);
  // };
  // const onChange = (e) => {
  //   let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
  //   setSearchQuery(value);
  //   // setPage(0);
  //   paginationModel.page = 0;
  //   if (timeoutId) {
  //     clearTimeout(timeoutId);
  //   }
  //   const newTimeoutId = setTimeout(() => {
  //     apply();
  //   }, 250);
  //   setTimeoutId(newTimeoutId);
  // };
  // const productColumnNames = [
  //   {
  //     field: "selection",
  //     // headerName: "",
  //     hideable: false,
  //     headerName: (
  //       // <Checkbox
  //       //   checked={selectAll || selectAllChecked.length === modifiedData.length}
  //       //   onChange={handleSelectAll}
  //       //   indeterminate={
  //       //     checkedItems.length > 0 && checkedItems.length < modifiedData.length
  //       //   }
  //       // />
  //       <Checkbox onChange={onCheckboxSelectionChange} checked={allChecked}/>
  //     ),
  //     sortable: false,
  //     resizable: false,
  //     ...actionColumnXS,
  //     renderCell: (params) => (
  //       <IconButton aria-label="select" sx={{ color: "#5881FE" }}>
  //         <Checkbox
  //           checked={checkedItems.includes(params.row.id)}
  //           onChange={() => {
  //             handleCheckboxChange(params);
  //           }}
  //         />
  //       </IconButton>
  //     ),
  //   },
  //   { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
  //   {
  //     field: "name",
  //     headerName: "Product Name",
  //     sortable: true,
  //     ...actionColumnFlexDouble,
  //   },
  //   {
  //     field: "barcode",
  //     headerName: "Primary Barcode",
  //     sortable: true,
  //     ...actionColumnXL,
  //   },
  //   {
  //     field: "sku",
  //     headerName: "SKU",
  //     sortable: true,
  //     ...actionColumnSM,
  //   },
  //   {
  //     field: "in_stock_qty",
  //     headerName: "In Stock",
  //     sortable: true,
  //     ...actionColumnMD,
  //   },
  //   {
  //     field: "sale_price",
  //     headerName: "Retail Price",
  //     sortable: true,
  //     ...actionColumnLG,
  //   },
  //   {
  //     field: "location",
  //     headerName: "Location",
  //     sortable: false,
  //     ...actionColumnMD,
  //   },
  //   {
  //     field: "threshold",
  //     headerName: "Threshold",
  //     sortable: true,
  //     ...actionColumnLG,
  //   },
  //   {
  //     field: "category_name",
  //     headerName: "Categories",
  //     sortable: false,
  //     resizable: false,
  //     ...actionColumnLG,
  //   },
  // ];

  const columnProductViewNames = [
    {
      columnNames: "Product Name",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Retail Price",
      sortName: "",
      width: "",
    },
    {
      columnNames: "On Hand",
      sortName: "",
      width: "",
    },
    // {
    //   columnNames: "Cost/Case($)",
    //   sortName: "",
    //   width: "",
    // },
    {
      columnNames: "Sent Quantity",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Received",
      sortName: "",
      width: "",
    },
    // {
    //   columnNames: "Units/Case",
    //   sortName: "",
    //   width: "",
    // },
    // {
    //   columnNames: "Quantity Unit",
    //   sortName: "",
    //   width: "",
    // },
    // {
    //   columnNames: "Total Units",
    //   sortName: "",
    //   width: "",
    // },
    // {
    //   columnNames: "Cost/Unit",
    //   sortName: "",
    //   width: "",
    // },
    {
      columnNames: "U/M",
      sortName: "",
      width: "3rem",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];
  const modifiedViewDatas = viewSelectedProducts
    ? viewSelectedProducts.map((item) => ({
        // id: undefined !== item?.product ? item.product.product_id : item.product_id && undefined !== item?.product ? item.product.id : item.id,
        id: item.hasOwnProperty('products') && undefined !== item?.products && undefined !==  item?.product_id
          ? item?.product_id : item?.id,
        name: item.hasOwnProperty('products') && undefined !== item?.products ? item?.products?.name : item?.name,
        categories: item.hasOwnProperty('products') && undefined !== item?.products
          ? item?.products?.categories?.map((cat) => cat.name).join(", ")
          : item.hasOwnProperty('categories') && undefined !== item?.categories && '' !== item?.categories && null !== item?.categories 
          ? item.categories?.map((cat) => cat.name).join(", "): item.hasOwnProperty('category_name') && undefined !== item?.category_name && '' !== item?.category_name && null !== item?.category_name ? item?.category_name : "",
        barcode:
        item.hasOwnProperty('products') && undefined !== item?.products ? item?.products?.barcode : item?.barcode,
        sale_price:
        item.hasOwnProperty('products') && undefined !== item?.products
            ? item?.products?.sale_price
            : item.sale_price,
        in_stock_qty:
        item.hasOwnProperty('products') && undefined !== item?.products
            ? item?.products?.in_stock_qty
            : 'N.A.',
        quantity: undefined !== item?.quantity ? parseInt(item.quantity) : null,
        received_qty:undefined !== item?.quantity ? parseInt(item.quantity) : null,
      }))
    : [];
    // useEffect(() => {
    //   if (fetchRef.current === true) {
    //     setAllChecked(false);
    //     if (!isChecked) {
    //       fetchData();
    //     } 
    //     // else {
    //     //   fetchDatathreshold();
    //     // }
    //     fetchRef.current = false;
    //   }
    // }, [searchQuery, paginationModel, sortModel, loading, isChecked,modalOpen, isAllProductsChecked]);

    // useEffect(() => {
    //   let searchExist = modifiedViewDatas.findIndex((x) => x.additionalRow == true);
    //   let searchViewExist = viewSelectedProducts.findIndex((x) => x.additionalRow == true);
    //   if (modifiedViewDatas.length > 0 && effectRunData.current && (searchExist == -1 && searchViewExist == -1)) {
    //     let array = [];
    //     var addInput = {
    //       id: '',
    //       name: '',
    //       categories: '',
    //       barcode: '',
    //       item_code: '',
    //       sale_price: '',
    //       in_stock_qty: '',
    //       quantity: '',
    //       cost: '',
    //       quantityUnit: '',
    //       unit_case:'',
    //       total_unit:'',
    //       additionalRow:true
    //     };
    //     array = [...viewSelectedProducts];
    //     setViewSelectedProducts(array);
    //     effectRunData.current = false;
    //   }
    // }, [viewSelectedProducts]);

  // useEffect(() => {
  //   if (addProductClicked) {
  //     if (allLocationProductFLag === 1) {
  //       setAddProductClicked(false);
  //       fetchData();
  //     } else {
  //       if (thresholdProductClicked) {
  //         fetchDatathreshold();
  //       }
  //     }
  //   }
  // }, [addProductClicked, thresholdProductClicked]);

  const handleSearchList = () => {
    if (isChecked) {
      // fetchDatathreshold();
    } else {
      fetchData();
    }
  };

  const handleColumn = (coulmn) => {
    if (coulmn.sortName === columnProductViewNames) {
      if (sortType === "asc") {
        // handleSortColumn(coulmn, "desc");
      } else {
        // handleSortColumn(coulmn, "asc");
      }
    } else {
      // handleSortColumn(coulmn, "asc");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    // let data = {
    //   created_at: purchaseOrderData.created_at,
    //   deleted_at: purchaseOrderData.deleted_at,
    //   id: purchaseOrderData.id,
    //   location: selectedLocationSourceData.id,
    //   location_id: selectedLocationSourceData.id,
    //   locationCity: purchaseOrderData.locations?.city,
    //   locationCountry: purchaseOrderData.locations?.country,
    //   locationState: purchaseOrderData.locations?.state,
    //   vendors: {
    //     id: selectedVendorData.id,
    //   },
    //   locations: {
    //     id: selectedLocationSourceData.id,
    //   },
    //   users: {
    //     id: perchesOrderUserData.id,
    //   },
    //   categories: selectedCategories,
    //   show_all: isAllProductsChecked == true ? true : false,
    //   query: {
    //     limit: paginationModel.pageSize,
    //     page: paginationModel.page + 1,
    //     order:
    //       sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
    //     order_type:
    //       sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
    //     filter: searchQuery,
    //     allProdFlag: allLocationProductFLag === 1 ? 1 : 0,
    //   },
    // };
    let data = {
      locations: selectedLocationSourceData.id,
      categories: selectedCategories,
      location: selectedLocationSourceData.id,
      location_id: selectedLocationSourceData.id,
      locationCity: purchaseOrderData.locations?.city,
      locationCountry: purchaseOrderData.locations?.country,
      locationState: purchaseOrderData.locations?.state,
      show_all: isAllProductsChecked == true ? true : false,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
        allProdFlag: allLocationProductFLag === 1 ? 1 : 0,
      },
    };
    try {
      editManualProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            setProductData(response.data.data.data);
            setAllLocationProductFLag(
              response.data.data.allLocationProductFLag
            );
            setTotalCount(response.data.data.totalItems);
            const cat = response.data.data?.category;
            const categoriesDropDownData = cat?.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesDropDownData);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setProductData([]);
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setProductData([]);
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  // const fetchProductData = async () => {
  //   const data = {
  //     vendor: selectedVendorData.id,
  //     location: selectedLocationData.id,
  //     vendors: selectedVendorData.id,
  //     locations: selectedLocationData.id,
  //   };
  //   setLoading(true);
  //   try {
  //     getProductForEdit(data)
  //       .then((response) => {
  //         if (response.data.flag === true) {
  //           setLocationAllProduct(response.data.data.product);
  //           setLoading(false);
  //         } else {
  //           setLocationAllProduct([])
  //           setTotalCount(0);
  //           setLoading(false);
  //           let actionData = response;
  //           error({ actionData });
  //           myContext.handleLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setLocationAllProduct([])
  //         setTotalCount(0);
  //         setLoading(false);
  //         let actionData = err;
  //         error({ actionData });
  //         myContext.handleLoading(false);
  //       });
  //   } catch (error) {
  //     setLocationAllProduct([])
  //     setTotalCount(0);
  //     setLoading(false);
  //     myContext.handleLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     selectedVendorData?.id &&
  //     selectedLocationData?.id &&
  //     locationRef.current
  //   ) {
  //     fetchProductData();
  //     locationRef.current = false;
  //   }
  //   // effectRun.current = true; // Ensures fetchProductData runs only after the initial render
  // }, [selectedVendorData, selectedLocationData]);

  // function reportDateFormate(dateString) {
  //   if (!dateString) {
  //     return "";
  //   }
  //   const date = moment(dateString);
  //   const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //   return formattedDate;
  // }

  // const [isLocationChanged, setIsLocationChanged] = useState(false);
  // const [isVendorChanged, setIsVendorChanged] = useState(false);
  const formik = useFormik({
    initialValues: {
      destination_location:
        undefined !==
          loaderResponse?.data?.data?.transfer_order?.destination_location &&
        null != loaderResponse?.data?.data?.transfer_order?.destination_location
          ? loaderResponse?.data?.data?.transfer_order?.destination_location
          : [],
      source_location:
        undefined !==
          loaderResponse?.data?.data?.transfer_order?.source_location &&
        null != loaderResponse?.data?.data?.transfer_order?.source_location
          ? loaderResponse?.data?.data?.transfer_order?.source_location
          : [],
          reference_number:
        undefined !==
          loaderResponse?.data?.data?.transfer_order?.reference_number &&
        null != loaderResponse?.data?.data?.transfer_order?.reference_number
          ? loaderResponse?.data?.data?.transfer_order?.reference_number
          : "",
      status:
        undefined !== loaderResponse?.data?.data?.transfer_order?.status &&
        null != loaderResponse?.data?.data?.transfer_order?.status
          ? loaderResponse?.data?.data?.transfer_order?.status
          : "",
      remark: undefined !== loaderResponse?.data?.data?.transfer_order?.remark &&
        null != loaderResponse?.data?.data?.transfer_order?.remark
          ? loaderResponse?.data?.data?.transfer_order?.remark
          : "",
    },
    validationSchema: Yup.object({
      reference_number: Yup.string().trim().required(
        Constants.TRANSFER_ORDER_REFRENCE_NUMBER_REQUIRED
      ),
    }),
    onSubmit: async (values) => {
      setVisible(false);
      myContext.handleLoading(true);
      const payload = {
        source_location: selectedLocationSourceData.id,
        destination_location: selectedLocationDestinationData.id,
        reference_number: values.reference_number,
        status: values.status,
        remark: values.remark,
        transfer_order_items:JSON.stringify(viewSelectedProducts),
        products: JSON.stringify(
          viewSelectedProducts.map((product) => {
            // Find matching product in perchesOrderData by id or product_id
            const matchedProduct = perchesOrderData.find(
              (p) => p.id === product.id || p.id === product.product_id
            );

            const received_qty = parseInt(matchedProduct?.received_qty) ?? parseInt(product.received_qty);
            const quantity = parseInt(product.quantity);
            const measureUnit = matchedProduct?.measureUnit ?? product.selected_unit_of_measure
            const umData = product.product_unit_measures.find(
              (um) => um.id == measureUnit
            );
            const base_quantity = umData ? umData.base_qty : 1;

            return {
              ...product.products,
              received_qty,
              measureUnit,
              quantity,
              base_quantity
            };
          })
        ),
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.PURCHASE_ORDER_EDIT_API_ROUTE,
      });
    },
  });
  const handleInputChange = (event, id, field_name) => {
    event.preventDefault();
    const { value } = event.target;
    let index = perchesOrderData.findIndex((x) => x.id === id);
    if (index > -1) {
      let obj = { ...perchesOrderData[index] };
      let sentQty = { ...viewSelectedProducts[index] };
      obj[field_name] = value;
      switch (field_name) {
        case "received_qty":
          obj.quantityErrors =
            value.length === 0
              ? Constants.PURCHASING_ORDER_SELECT_QUANTITY
              : parseInt(value) < 1
              ? Constants.PURCHASING_ORDER_QUANTITY_MIN_VALUE
              : parseInt(value) > 1000000
              ? Constants.PURCHASING_ORDER_QUANTITY_MAX_VALUE
              : parseInt(value) > parseInt(sentQty.quantity)
              ? 'Received Quantity can not be greater than Sent Quantity.'
              : "";
          break;
        default:
          break;
      }
      let array = [
        ...perchesOrderData.slice(0, index),
        obj,
        ...perchesOrderData.slice(index + 1),
      ];
      setPerchesOrderData(array);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.vendors.length <= 0 ||
      formik.errors.vendors ||
      formik.errors.locations ||
      formik.errors.quantity ||
      formik.errors.cost ||
      formik.errors.unit_case ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };

  // const handleQuantityUnitChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedQuantityUnit(value);
  //   formik.setFieldValue("status", value);
  // };

  // const hadleFromDateSelect = (e) => {
  //   const selectedDate = e.value;
  //   formik.setFieldValue("payment_due_date", selectedDate);
  //   setFromDateView(selectedDate);
  // };

  const toDateCalendarRef = useRef(null);
  const [toDateFocus, setToDateFocus] = useState(false);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       null !== calendarRef.current &&
  //       calendarRef.current &&
  //       fromDateFocus
  //     ) {
  //       calendarRef.current.hide();
  //       setFromDateFocus(false);
  //     }
  //     if (
  //       null !== toDateCalendarRef.current &&
  //       toDateCalendarRef.current &&
  //       toDateFocus
  //     ) {
  //       toDateCalendarRef.current.hide();
  //       setToDateFocus(false);
  //     }
  //   };
  // }, [fromDateFocus, toDateFocus]);
  const sortRef = useRef(sortModel);

  const onSelectSourceLocation = (e) => {
    if (undefined !== e.value) {
      locationRef.current = true;
      if((undefined != selectedLocationDestinationData.id && null != selectedLocationDestinationData.id && '' != selectedLocationDestinationData.id) && e.value.id == selectedLocationDestinationData.id){
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TRANSFER_ORDER_MODULE,
          alertDescription: Constants.TRANSFER_ORDER_SOURCE_DESTINATION_CAN_NOT_BE_SAME,
        });
      }
      else{
        setSelectedSourceLocationData(e.value);
        setPerchesOrderData([]);
        setViewSelectedProducts([]);
        setCheckedItems([]);
        selectedProductRef.current = []
        setSelectAll(false);
        setIsChecked(false);
        formik.setFieldValue("source_location", e.value);
        setFilteredProducts('');
        setProducts([]);
      }
    }
  };
  const onSelectDestinationLocation = (e) => {
    if (undefined !== e.value) {
      if(e.value.id == selectedLocationSourceData.id){
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TRANSFER_ORDER_MODULE,
          alertDescription: Constants.TRANSFER_ORDER_SOURCE_DESTINATION_CAN_NOT_BE_SAME,
        });
      }
      else{
        locationRef.current = true;
        setSelectedDestinationLocationData(e.value);
        setPerchesOrderData([]);
        setViewSelectedProducts([]);
        setCheckedItems([]);
        selectedProductRef.current = []
        setSelectAll(false);
        setIsChecked(false);
        formik.setFieldValue("destination_location", e.value);
        setFilteredProducts('');
        setProducts([]);
      }
      // setAutoSelectedProducts('');
    }
  };
  return (
    <Box flex="1" pb="3.31rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            // pass param data in tax list page
            props.navigate(Constants.TRANSFER_ORDER_PATH, {
              state: stateData.state,
            });
          }}
          breadCrumNames={["Transfer Orders", "Receive Transfer Order"]}
          breadCrumTitle={"Receive Transfer Order"}
        />
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <Flex
          pr={formik.values.status == 'RECE' ? "1.5rem" : 0 }
          >
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              // pass param data in Transfer order list page
              if(undefined != stateData.state && null != stateData.state && undefined != stateData.state.inventoryTrailReport && null != stateData.state.inventoryTrailReport && '' != stateData.state.inventoryTrailReport && 1 == stateData.state.inventoryTrailReport){
                props.navigate(Constants.INVENTORY_TRAIL_REPORT_ROUTE, {
                  state: stateData.state,
                });
              }
              else{
                props.navigate(Constants.TRANSFER_ORDER_PATH, {
                  state: stateData.state,
                });
              }
            }}
            isDisabled={buttonDisable}
          />
          </Flex>
          {formik.values.status != 'RECE' &&
          <PosFormButton
            isDisabled={
              !formik.values.source_location ||
              !formik.values.destination_location ||
              !formik.values.reference_number ||
              formik.errors.source_location ||
              // formik.values.locations.length <= 0 ||
              formik.errors.source_location ||
              formik.errors.destination_location ||
              formik.errors.reference_number ||
              (viewSelectedProducts.length) <= 0 ||
              // !perchesOrderData ||
              // perchesOrderData.length === 0 ||
              perchesOrderData.some(
                (item) =>
                  !item.received_qty ||
                  parseInt(item.received_qty) <= 0 ||
                  item.quantityErrors
              ) ||
              // perchesOrderData.some(
              //   (item) =>
              //     item.cost === undefined ||
              //     parseFloat(item.cost) < 0 ||
              //     item.costErrors
              // ) ||
              // perchesOrderData.some((item) => item.unitCaseErrors) ||
              buttonDisable
              || formik.values.status == 'RECE'
                ? true
                : false
            }
            buttonsubmit="Received"
            SubmitButton={true}
            onClick={formik.handleSubmit}
          />}
        </Stack>
      </Flex>
      <Box
        mt={{ base: 2, md: "2rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "2.75rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
      >
        <Flex
          fontSize="1.23rem"
          color={Colors.posTextInfo}
          ml={{ base: "0", md: "1.1rem" }}
          pt={{ base: "2rem", md: "2rem", lg: "3rem" }}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
          }}
        >
          <Box
            backgroundColor={Colors.modifierTable}
            ml={{ base: "1rem" }}
            mt={{ base: "1rem" }}
            style={{
              width: "2.13rem",
              height: "2.13rem",
              flexShrink: 0,
              marginRight: "0.63rem",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Img
              src={invoice}
              alt="Transfer Order Details"
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </Box>
          <Text mt={{ base: "1rem" }}>Transfer Order Details</Text>
        </Flex>
        <Divider mt={"0.56rem"} />
        <Flex
          direction={{ base: "column", md: "column", lg: "row" }}
          p={{ base: "0.3rem", md: "6", lg: "6" }}
          pl={{ base: "5.7" }}
        >
          <Box
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                mb={{ base: "0.5rem", md: "0" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Source Location
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
                <Tippy
                  content="Select the Source Location for which you want to transfer products from."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
              <PosDropDown
                  // disabled={formik.values.status=='RECE'}
                  disabled={true}
                  value={selectedLocationSourceData}
                  onChange={onSelectSourceLocation}
                  onBlur={(e) => {
                    formik.setFieldTouched("source_location", true);
                    formik.handleBlur(e);
                  }}
                  options={locationSourceData}
                  lableAvailable={true}
                  optionLabel="name"
                  className="w-full md:w-20rem"
                  placeholder="--Select a Source Location--"
                />
                <Text color={Colors.errorColor} textAlign="end">
                  {formik.touched.source_location && formik.errors.source_location ? (
                    <span>{formik.errors.source_location}</span>
                  ) : null}
                </Text>
              </Box>
            </Flex>
            {/* Destination Location */}
            {undefined != selectedLocationSourceData.id && null != selectedLocationSourceData.id && '' != selectedLocationSourceData.id > 0 && (
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                mb={{ base: "0.5rem", md: "0" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Destination Location
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
              </Box>
              <Box>
                <PosDropDown
                  // disabled={formik.values.status=='RECE'}
                  disabled={true}
                  value={selectedLocationDestinationData}
                  onChange={onSelectDestinationLocation}
                  onBlur={(e) => {
                    formik.setFieldTouched("destination_location", true);
                    formik.handleBlur(e);
                  }}
                  options={locationDestinationData}
                  lableAvailable={true}
                  optionLabel="name"
                  className="w-full md:w-20rem"
                  placeholder="--Select a Destination Location--"
                />
                <Text color={Colors.errorColor} textAlign="end">
                  {formik.touched.destination_location && formik.errors.destination_location ? (
                    <span>{formik.errors.destination_location}</span>
                  ) : null}
                </Text>
              </Box>
            </Flex>)}
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                flexShrink={0}
                display="flex"
                pr={{ base: "1rem" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Reference Number
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
                <Tippy
                  content="This Reference number helps in accurate tracking of the Transfer order to the Incoive."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
              <PosInput
                id="reference_number"
                mt={{ base: "1rem" }}
                placeholder={"Enter a Reference Number"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.reference_number}
                handleBlur={formik.handleBlur}
                onKeyDown={handleKeyDown}
                posInput={true}
                width={{ base: "17.38rem", md: "17.38rem", lg: "100%" }}
                inputType={"text"}
                disabled={formik.values.status=='RECE'}
              />
              <Text color={Colors.errorColor} textAlign="end">
                {formik.touched.reference_number && formik.errors.reference_number ? (
                  <span>{formik.errors.reference_number}</span>
                ) : null}
              </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            justifyContent="flex-start"
            ml={{
              base: "0",
              sm: "0",
              md: "0",
              lg: "1.75rem",
              xl: "7.75rem",
            }}
          >
            <Flex p={2.5}>
            <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                flexShrink={0}
                display="flex"
                pr={{ base: "1rem" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Remark{formik.values.status =='RECE' ? " :":""}
              </Box>
              {formik.values.status!='RECE' ? 
              (<PosInput
                id="remark"
                mt={{ base: "1rem" }}
                placeholder={"Enter a Remark"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.remark || ''}
                handleBlur={formik.handleBlur}
                onKeyDown={handleKeyDown}
                posInput={true}
                width={{ base: "17.38rem", md: "17.38rem", lg: "100%" }}
                inputType={"text"}
                disabled={formik.values.status=='RECE'}
              />):
                (<Box
                  color={Colors.posTextInfo}
                  fontWeight={500}
                  flexShrink={0}
                  display="flex"
                  pr={{ base: "1rem" }}
                  style={{
                    fontSize: "0.94rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    alignItems: "center",
                  }}>
                  {formik.values.remark || 'N.A.'}
                </Box>
              )}
              
            </Flex>
            <Flex p={2.5}>
              <Box
                  color={Colors.posTextInfo}
                  fontWeight={500}
                  flexShrink={0}
                  display="flex"
                  pr={{ base: "1rem" }}
                  style={{
                    fontSize: "0.94rem",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    alignItems: "center",
                  }}
                >
                  Status
                  {formik.values.status == 'RECE' ? 
                  (<Text color={'#008000'} ml={2}>
                    Received
                  </Text>):
                  (<Text color={Colors.errorColor} ml={4}>
                    Generated
                  </Text>)}
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Divider />
         {/* <Flex
          direction={{ base: "column", md: "column", lg: "row" }}
          alignitems={{ base: "start" }}
          pl={{ base: "1.81rem" }}
        >
          <Spacer />
          <Box
            mb={{ base: "1rem" }}
            mr={{ md: "3.56rem" }}
            mt={{ base: "1rem" }}
          >
            <PosFormButton
              buttonsubmit={"Add Multiple Products"}
              width={"15.25rem"}
              onClick={() => {
                fetchRef.current = true
                setModal(true);
                selectedProdQtyRef.current = perchesOrderData;
                oldselectedRef.current = selectedProductRef.current
              }}
              onClick={() => {
                setVisible(true);               
                openDialog();
              }}
              isDisabled={
                !formik.values.source_location ||
                !formik.values.destination_location ||
                !formik.values.reference_number ||
                formik.values.source_location.length <= 0 ||
                formik.errors.source_location ||
                // formik.values.locations.length <= 0 ||
                formik.errors.source_location ||
                formik.errors.destination_location ||
                formik.errors.reference_number ||
                buttonDisable
                  ? true
                  : false
              }
            />
          </Box>
          <>
                <Dialog
                  header={<div style={{ padding: 0, margin: 0 }}> </div>}
                  visible={visible}
                  onHide={onHideDialog}
                  style={{ width: isMobileDialog ? "80%" : "70%" }}
                  closable={false}
                  closeOnEscape={false}
                >
                  <ChakraProvider theme={theme}>
                  <Box
                    position="sticky"
                    top="0"
                    zIndex="1000"
                    backgroundColor="white"
                    pb={2}
                  >
                  <Flex
                      minWidth="max-content"
                      gap="2"
                      mt={4}
                      mb={4}
                      alignItems="center"
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Flex alignSelf="flex-start" fontSize="1.5rem" fontWeight={600} color="rgb(1, 0, 72)">
                        Select Products
                      </Flex>
                      <Spacer />
                      <ButtonGroup alignSelf="flex-end">
                        <PosFormButton
                          onClick={modalClose}
                          buttonText="Cancel"
                          CancelButton={true}
                          isDisabled={buttonDisable}
                        />
                        <PosFormButton
                          isDisabled={
                            checkedItems.length <= 0 ||
                            (checkedItems.length === 1 && checkedItems[0] === "") ||
                            (isChecked && perchesOrderData.length === 0) ||
                            buttonDisable
                          }
                          buttonsubmit="Add Products"
                          SubmitButton={true}
                          onClick={handleSelectedViewProduct}
                        />
                      </ButtonGroup>
                    </Flex>
                    <Flex alignItems="center">
                    </Flex>
                    <Flex
                      gap="2"
                      mt={4}
                      mb={4}
                      flexDirection={{ base: "column", sm: "column", md: "row" }}
                    >
                      <PosSearch
                      value={searchQuery}
                      onChange={onChange}
                      onSearch={handleSearchList}
                      handleClear={handleClear}
                    />
                    <PosDropDown
                      options={categories}
                      value={selectedCategories}
                      onChange={(e) => {
                        clickIfapplyCat(e.value);
                      }}
                      multiSelect={true}
                      optionLabel="label"
                      placeholder="Categories"
                      lableAvailable={true}
                      width="100%"
                      height="2.5rem"
                      className="w-full md:w-20rem"
                      maxSelectedLabels={2}
                    />
                    <Spacer />
                    </Flex>
                    {showTable ? null : (
                      <>
                        <Box mb={{ base: "0.88rem", md: "0.88rem" }}>
                        </Box>
                        {loading ? (
                          <PosProgress />
                        ) : isChecked ? (
                          allLocationProductFLag == 1 ? (
                            <PosNoDataFound title={"No more products found."}  />
                          ) : (
                            <Box
                              sx={{
                                overflowX: "auto",
                                maxHeight: "30.91rem",
                                maxWidth: "100%",
                                "& .Product Name": {
                                  backgroundColor: Colors.loginFooter,
                                },
                              }}
                            >
                              {(modifiedData.length === 0 && isAllProductsChecked)? (
                                <PosNoDataFound
                                title={"No more products found."} 
                                />
                              ) : (
                                <PosDataGridTable
                                  columns={productColumnNames}
                                  rows={modifiedData}
                                  totalcount={totalcount}
                                  columnVisibilityModel={
                                    olColumnVisibilityModel
                                  }
                                  onColumnVisibilityModelChange={(newModel) =>
                                    setOlColumnVisibilityModel(newModel)
                                  }
                                  paginationModel={paginationModel}
                                  paginationMode="server"
                                  sortingMode="server"
                                  onPaginationModelChange={(
                                    newPaginationModel
                                  ) => {
                                    fetchRef.current = true;
                                    setPaginationModel(newPaginationModel);
                                  }}
                                  slots={{
                                    toolbar: GridToolbar,
                                  }}
                                  sortModel={sortModel}
                                  onSortModelChange={(newSortModel) => {
                                    sortRef.current = sortModel;
                                    fetchRef.current = true;
                                    setSortModel(newSortModel);
                                  }}
                                  fileName={"POLowthresholdProducts"}
                                />
                              )}
                            </Box>
                          )
                        ) : (modifiedData.length === 0 && isAllProductsChecked) ? (
                          <PosNoDataFound title={"No more products found."}  />
                        ) : (
                          <Box
                            sx={{
                              overflowX: "auto",
                              maxHeight: "30.91rem",
                              maxWidth: "100%",
                              "& .Product Name": {
                                backgroundColor: Colors.loginFooter,
                              },
                            }}
                          >
                            <PosDataGridTable
                              columns={productColumnNames}
                              rows={modifiedData}
                              totalcount={totalcount}
                              columnVisibilityModel={olColumnVisibilityModel}
                              onColumnVisibilityModelChange={(newModel) =>
                                setOlColumnVisibilityModel(newModel)
                              }
                              slots={{
                                toolbar: GridToolbar,
                              }}
                              paginationModel={paginationModel}
                              paginationMode="server"
                              sortingMode="server"
                              onPaginationModelChange={(newPaginationModel) => {
                                fetchRef.current = true;
                                setPaginationModel(newPaginationModel);
                              }}
                              sortModel={sortModel}
                              onSortModelChange={(newSortModel) => {
                                sortRef.current = sortModel;
                                fetchRef.current = true;
                                setSortModel(newSortModel);
                              }}
                              fileName={"POProducts"}
                            />
                          </Box>
                        )}
                      </>
                    )} 
                  </Box>
                  </ChakraProvider>
                </Dialog>
          </>
        </Flex> */}
        {!formik.values.apply_on_all_prods &&
          viewSelectedProducts &&
          viewSelectedProducts.length > 0 && (
            <Box
              borderRadius="0.63rem"
              mt={3}
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowX: "auto",
              }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead
                    alignitems="start"
                    sx={{ backgroundColor: Colors.modifierTable }}
                  >
                    <TableRow>
                      {columnProductViewNames.map((column, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.94rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-0.01rem",
                            fontStyle: "normal",
                            textAlign: column.textAlign || "center",
                            maxHeight: "42rem",
                            color:"#010048",
                          }}
                        >
                          <Flex flexDirection={"row"}>
                          {column.columnNames}
                          {column.columnNames=='Cost/Case($)' || column.columnNames=='Units/Case' ? (<Tippy
                            content={column.columnNames=='Cost/Case($)' ? 'This will your cost per case. For example, $50 for Kellogs Cornflakes 300Gm X 20':'Please enter the quantity of individual units per case. For example, for Kellogs Cornflakes 300Gm X 20, you should enter 20.'}
                            interactive={true}
                            maxWidth={300}
                            placement="top"
                            animation="fade"
                            inertia={true}
                            moveTransition="transform 0.2s ease-out"
                            theme="tomato"
                          >
                            <Image
                              src={toolTip}
                              alt="Tooltip"
                              ml={{ base: "0.3rem", md: "0.3rem" }}
                            />
                          </Tippy>): null}
                          </Flex>
                          {column.sortName.length > 0 ? (
                            <TableSortLabel
                              active={
                                column.sortName === columnProductViewNames
                              }
                              direction={
                                column.sortName === columnProductViewNames
                                  ? sortType
                                  : "asc"
                              }
                              onClick={() => handleColumn(column)}
                            />
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modifiedViewDatas.map((row,i) => {
                      let index = perchesOrderData.findIndex(
                        (x) => x.id === row.id
                      );
                      return (
                          <TableRow
                          key={i}
                        >
                          <TableCell>                 
                          {index === -1 ? (
                            null
                          ): (
                            <VStack align="start">
                              <Box
                                style={{
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                  color:"#010048"
                                }}
                              >
                                {row.name} ({row.categories})
                              </Box>
                            </VStack>
                          )}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {row.barcode || "N.A"}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {null != row.sale_price && '' != row.sale_price ? "$"+row.sale_price : "N.A."}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {row.in_stock_qty}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >{ (row.quantity) || ""}
                          </TableCell>
                          <TableCell>
                            <Input
                              id="received_qty"
                              // disabled={modifiedViewDatas.length-1 == i}
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "received_qty")
                              }
                              disabled={formik.values.status == 'RECE'}
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = (originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue;
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "received_qty");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].received_qty
                                  : row.quantity) || ""
                              }
                              onKeyPress={(e) => {
                              if (e.key === "-" || e.key === "." || e.key === "e") { // Prevent non-integer characters
                                  e.preventDefault();
                                }
                              }}
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.quantityErrors
                                  ?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.quantityErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.quantityErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell>
                          {/* <TableCell align="center">
                            <Input
                              id="unit_case"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "unit_case")
                              }
                              disabled={modifiedViewDatas.length-1 == i}
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "unit_case");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].unit_case
                                  : row.unit_case) || ""
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.unitCaseErrors?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.unitCaseErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.unitCaseErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell> */}
                          {/* <TableCell align="center">
                            <Input
                              disabled={true}
                              id="total_units"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "total_units")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "total_units");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].total_unit
                                  : 'N.A.') || "N.A."
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                            />
                          </TableCell> */}
                          {/* <TableCell align="center">
                            <Input
                              disabled={true}
                              id="cost_per_unit"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "cost_per_unit")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "cost_per_unit");
                              }}
                              value={
                                (index > -1
                                  ? (perchesOrderData[index].cost_per_unit)
                                  : 'N.A.') || "N.A."
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                            />
                          </TableCell> */}
                          {/* <TableCell align="center">
                            <Box position="relative" display="inline-block">
                              <Select
                                style={{
                                  width: "5.2rem",
                                  padding: "0.63rem",
                                  backgroundColor: "white",
                                  border: "0.6px solid #01004833",
                                  borderRadius: "0.44rem",
                                  textAlign: "start",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.63rem",
                                  flexShrink: 0,
                                  color:"#010048",
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                                appearance="none"
                                value={
                                  index > -1
                                    ? perchesOrderData[index].quantityUnit
                                    : row.quantityUnit
                                }
                                onChange={(e) =>
                                  handleInputChange(e, row.id, "quantityUnit")
                                }
                                onBlur={(e) =>
                                  handleInputChange(e, row.id, "quantityUnit")
                                }
                              >
                                <option value="CAS">Case</option>
                                <option value="PLT">Pallet</option>
                                <option value="EAC">Each</option>
                              </Select>
                            </Box>
                          </TableCell> */}
                          {perchesOrderData[index] && undefined != perchesOrderData[index].unit_of_measures && perchesOrderData[index].unit_of_measures.length>0 ? (
                            <TableCell align={"left"}>
                              <Box position="relative" display="inline-block">
                                <Select
                                  style={{
                                    width: "8rem",
                                    padding: "0.63rem",
                                    backgroundColor: "white",
                                    border: "0.6px solid #01004833",
                                    borderRadius: "0.44rem",
                                    textAlign: "start",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.63rem",
                                    flexShrink: 0,
                                    color: "#010048",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                  disabled={true}
                                  appearance="none"
                                  value={
                                    index > -1 ? perchesOrderData[index].measureUnit : row.measureUnit
                                  }
                                  onChange={(e) => handleInputChange(e, row.id, "measureUnit")}
                                  onBlur={(e) => handleInputChange(e, row.id, "measureUnit")}
                                >
                                  {perchesOrderData[index].unit_of_measures.map((data) => (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  ))}
                                </Select>
                              </Box>
                            </TableCell>
                          ):(
                            <TableCell align="center">
                              <Box position="relative" display="inline-block">
                              <Input
                              disabled={true}
                              id="measureUnit"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "measureUnit")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              value={"N.A."}
                              type="text"
                            />
                            </Box>
                            </TableCell>
                          )}
                          <TableCell align={formik.values.status == 'RECE' ? "center":""}>
                            {index == -1 || formik.values.status == 'RECE' ? (
                              '-'
                            ) : 
                            (<DeleteIcon
                              color={Colors.posCancleButtonMuiRed}
                              style={{
                                width: "1.13rem",
                                height: "1.13rem",
                                flexShrink: 0,
                                cursor:"pointer"
                              }}
                              onClick={() => handleSingleDelete(row.id)}
                            />)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        <Box pb={8}></Box>
      </Box>
    </Box>
  );
};
export default WithRouter(TransferOrderEdit);
